<template>
  <div class="loginBox">
    <a-form
      :model="formState"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }"
      @finish="onFinish"
      @finishFailed="onFinishFailed"
    >
      <a-form-item
        label="用户名"
        name="username">
        <a-input v-model:value="formState.username" placeholder="请输入用户名称" />
      </a-form-item>
  
      <a-form-item
        label="密码"
        name="password">
        <a-input-password v-model:value="formState.password" />
      </a-form-item>
  
      <a-form-item :wrapper-col="{ offset: 5, span: 19 }">
        <a-button type="primary" html-type="submit">登录</a-button>
      </a-form-item>
    </a-form>
    <div>

    </div>
  </div>
</template>
<script setup>
import {  message } from 'ant-design-vue';
import { reactive,toRefs,getCurrentInstance} from 'vue'
const { proxy } = getCurrentInstance()
  let $api = proxy.$api
  import { useRouter } from 'vue-router';
const router = useRouter()
const state = reactive({
  formState:{
    loginType:'pc',
    username: '',
    password: '',
  },
  // 校验
});
const {formState} = toRefs(state);
const onFinish = ()=>{
  console.log(state.formState)
  //接口
  $api.webLogin(state.formState).then(res=>{
    console.log(res.data.authorization,'登录',res)
    if(res.code == 200){
      sessionStorage.setItem('token',res.data.authorization)
      message.success('登录成功');
      router.push({path:'/manage/case'})
    }else{
      message.error(res.msg)
    }
  })
  .catch(err => {
      console.log('error', err);
    });
}
const onFinishFailed = ()=>{}
</script>
<style lang="less" scoped>
.loginBox{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.ant-form{
  width: 300px;
}
</style>